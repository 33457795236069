import { reactive, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
export default defineComponent({
    name: "AgreementsSubmit",
    props: ["isSubmitting", "buttonText"],
    setup: function () {
        var store = useStore();
        var state = reactive({
            privicyChecked: false,
            privicyActive: false,
            pledgeChecked: false,
            pledgeActive: false,
            promiseChecked: false,
            understandChecked: false
        });
        onMounted(function () {
            if (store.state.termsAgreed) {
                state.privicyChecked = true;
                state.privicyActive = true;
                state.pledgeChecked = true;
                state.pledgeActive = true;
                state.promiseChecked = true;
                state.understandChecked = true;
                store.commit('setTermsAgreed', false);
            }
        });
        return {
            state: state
        };
    }
});
