<template>
    <div class="agreements_submit">
        <div class="agreements_submit__agreements">
            <p class="agreements_submit__agreements__notice">※下記２つは、リンクをクリックする事でチェックできるようになります。</p>
            <div class="agreements_submit__agreements__row">
                <input
                type="checkbox"
                id="privicy_checkbox"
                v-model="state.privicyChecked"
                :disabled="!state.privicyActive"
                />
                <label for="privicy_checkbox"></label>
                <a href="https://cloud.signate.jp/manabi-dx-quest/signate-collaboration-privacy-policy" target="_blank" @click="() => state.privicyActive = true">
                マナビDX Quest事業 プライバシーポリシー
                </a>
            </div>
            <div class="agreements_submit__agreements__row">
                <input
                type="checkbox"
                id="pledge_checkbox"
                v-model="state.pledgeChecked"
                :disabled="!state.pledgeActive"
                />
                <label for="pledge_checkbox"></label>
                <a href="https://cloud.signate.jp/manabi-dx-quest/signate-collaboration-pledge" target="_blank" @click="() => state.pledgeActive = true">
                マナビDX Quest 誓約書
                </a>
                に同意します
            </div>
            <p class="agreements_submit__agreements__notice">※下記２つも、必ず同意をお願いいたします。</p>
            <div class="agreements_submit__agreements__row">
                <input
                type="checkbox"
                id="promise_checkbox"
                v-model="state.promiseChecked"
                />
                <label for="promise_checkbox">
                    地域企業協働プログラムの協働先が確定した場合、地域企業協働プログラムの修了 (最終報告) まで、辞退/離脱しないことをお約束いただけますか？
                </label>
            </div>
            <div class="agreements_submit__agreements__row">
                <input
                type="checkbox"
                id="understand_checkbox"
                v-model="state.understandChecked"
                />
                <label for="understand_checkbox">
                    必ずしも協働先とマッチングしない可能性もございますが、その旨ご了承いただけますか？
                </label>
            </div>
        </div>
        <div class="btn_group">
            <button
                :disabled="isSubmitting || !state.pledgeChecked || !state.privicyChecked || !state.promiseChecked || !state.understandChecked"
                :class="{ submitting: isSubmitting }"
                type="submit"
                class="btn-md btn-blue shadow"
            >
                <i v-if="isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                {{ buttonText }}
            </button>
        </div>
    </div>
</template>
<script lang="ts" src="./AgreementsSubmit.ts" />
<style lang="scss" src="./AgreementsSubmit.scss" scoped>
</style>
