import { defineComponent, reactive, onMounted } from "vue";
import MyConfig from "@/_config";
export default defineComponent({
    name: "ReCapchaComponent",
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            sitekey: MyConfig.reCapcha_sitekey,
            widgetId: 0
        });
        function render() {
            setTimeout(function () {
                if (typeof window.grecaptcha === 'undefined') {
                    render();
                }
                else {
                    window.grecaptcha.render('recaptcha', {
                        sitekey: state.sitekey,
                        callback: submit
                    });
                }
            }, 500);
        }
        function submit(token) {
            emit('submit', { token: token });
        }
        onMounted(function () {
            render();
        });
        return {
            state: state
        };
    }
});
